import { CButton } from '@coreui/react'
import { FC } from 'react'
import CircularProgress from 'components/atoms/CircularProgress'
import clsx from 'clsx'

interface IProps {
  color: string
  type?: string
  className?: string
  onClick?: Function
  block?: boolean
  disabled?: boolean
  loading?: boolean
  children?: React.ReactNode
}

const Button: FC<IProps> = ({
  color = 'primary',
  children,
  className,
  block = false,
  disabled,
  loading = false,
  type = 'button',
  ...props
}) => {
  return (
    <CButton
      disabled={disabled || loading}
      block={block}
      color={color}
      type={type}
      className={clsx(className, 'd-flex flex-row align-items-center')}
      {...props}
    >
      {loading && <CircularProgress className="mr-2" />}
      <span className="">{children}</span>
    </CButton>
  )
}

export default Button

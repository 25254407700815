import Loading from 'pages/Common/Loading'

const AuthContainer = ({ children, loading }: any) => {
  if (!loading) {
    return <div className="app">{children}</div>
  } else {
    return <Loading />
  }
}

export default AuthContainer

import * as types from 'state/redux/constants'

const initialState = {
  user: {},
}

const userReducer = (
  state = initialState,
  action: { type: any; msg: any; err: any; data: any }
) => {
  switch (action.type) {
    case types.SET_USER_DATA:
      return { ...state, user: action.data }

    default:
      return state
  }
}

export default userReducer

import { CCol, CProgress, CRow } from '@coreui/react'
import PageContent from 'components/templates/PageContent'

const Loading = () => {
  return (
    <PageContent center>
      <CRow className="justify-content-center">
        <CCol xl="4" lg="6" md="8" sm="12" xs="12">
          <CRow className="justify-content-center mb-2">Loading</CRow>
          <CProgress color="info" value={100} animated className="mb-3" />
        </CCol>
      </CRow>
    </PageContent>
  )
}

export default Loading

import ReactDOM from 'react-dom'
import { Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Provider } from 'react-redux'
import App from 'App'
import UserContextProvider from 'context/UserContext'
import ErrorPage from 'pages/Common/Error'
import Loading from 'pages/Common/Loading'
import configureStore from 'state'
import { QueryClient, QueryClientProvider } from 'react-query'
import { PersistGate } from 'redux-persist/integration/react'
import 'i18n'

// @ts-ignore
function ErrorFallback({ error, resetErrorBoundary }) {
  return <ErrorPage errorMessage={error.message} />
}

const queryClient = new QueryClient()
const { store, persistor } = configureStore()

const MainApp = () => (
  <Suspense fallback={<Loading />}>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <UserContextProvider>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <App />
            </ErrorBoundary>
          </UserContextProvider>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  </Suspense>
)

ReactDOM.render(<MainApp />, document.getElementById('root'))

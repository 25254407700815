import React, { FC } from 'react'
import { CAlert, CCard, CCardBody, CCol, CContainer, CRow } from '@coreui/react'

interface IProps {
  errorMessage: string
}

const Error: FC<IProps> = ({ errorMessage }) => {
  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="6">
            <CCard>
              <CCardBody>
                <span className="clearfix">
                  <h1 className="float-left display-3 mr-4">500</h1>
                  <h4 className="pt-3">Oops, we have a problem!</h4>
                  <p className="text-muted float-left">The page you are looking for is facing technical issues</p>
                </span>
              </CCardBody>
            </CCard>
            <CAlert color="danger">{errorMessage}</CAlert>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Error

import { CCol, CContainer, CRow } from '@coreui/react'
import clsx from 'clsx'
import Button from 'components/atoms/Button'
import isEmpty from 'lodash/isEmpty'
import { FC } from 'react'

interface IProps {
  center?: boolean
  title?: string
  actionButton?: any
  children?: React.ReactNode
}

const PageContent: FC<IProps> = ({ children, center, title = '', actionButton }) => {
  return (
    <div className={clsx('c-app c-default-layout flex-row', center ? 'align-items-center' : '')}>
      <CContainer>
        {!(isEmpty(title) && isEmpty(actionButton)) && (
          <CRow className="justify-content-space-between mb-4 align-items-center">
            <CCol md="6">{!isEmpty(title) && <h2 className="card-title mb-0">{title}</h2>}</CCol>
            <CCol className="d-flex justify-content-end" md="6">
              {!isEmpty(actionButton) && (
                <Button onClick={actionButton.onClick} color="primary">
                  {actionButton?.name}
                </Button>
              )}
            </CCol>
          </CRow>
        )}
        {children}
      </CContainer>
    </div>
  )
}

export default PageContent

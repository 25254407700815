import * as types from 'state/redux/constants'

const initialState = {
  tempUser: {},
}

const authReducer = (
  state = initialState,
  action: { type: any; msg: any; err: any; data: any }
) => {
  switch (action.type) {
    case types.SET_TEMPORARY_USER:
      return {
        ...state,
        tempUser: action.data,
      }

    default:
      return state
  }
}

export default authReducer

import axios from 'axios'
import { parseCookies, destroyCookie } from 'nookies'

export const HttpRequest = (type: any, url: string, data = {}, headers = {}, others = {}) => {
  const BaseUrl = process.env.REACT_APP_BASE_URL || 'https://staging-352717.uk.r.appspot.com/api'
  const { accessToken } = parseCookies()
  let headersData = {}

  if (accessToken) {
    headersData = {
      ...headers,

      Authorization: `${accessToken}`,
    }
  } else {
    headersData = {
      ...headers,
    }
  }

  return new Promise((resolve, reject) => {
    axios({
      method: type,
      responseType: 'json',
      url: BaseUrl + url,
      data,
      params: type === 'get' ? data : '',
      headers: headersData,
    })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error?.response?.status === 403) {
          // Need to write function here to fetch the new token with refreshToken if accessToke is expired.

          destroyCookie(null, 'accessToken') // Clear cookies here on unauthorised error.
          destroyCookie(null, 'refreshToken')
        }
        reject(error)
      })
  })
}

import { lazy } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import AuthContainer from 'containers/AuthContainer'
import NotFound from 'pages/Common/NotFound'

const DashboardPage = lazy(() => import('pages/Dashboard'))
const CompanyListPage = lazy(() => import('pages/Company/CompanyList'))
const EmployeeListPage = lazy(() => import('pages/Employee/EmployeeList'))
const Config = lazy(() => import('pages/Config/Config'))
const GoogleSheetsPage = lazy(() => import('pages/Company/GoogleSheetsPage'))

const LoginPage = lazy(() => import('pages/LoginRegister/Login'))
const VerifyPage = lazy(() => import('pages/LoginRegister/Verify'))
const SetPassword = lazy(() => import('pages/LoginRegister/SetPassword'))
const ForgotPassword = lazy(() => import('pages/LoginRegister/ForgotPassword'))

interface IProps {
  loading: boolean
  isUser: boolean
}

const LayoutRouter = ({ loading, isUser }: IProps) => {
  return (
    <Router>
      <AuthContainer loading={loading}>
        <Switch>
          {isUser ? (
            <Switch>
              <Route render={() => <CompanyListPage />} exact path="/company-list" />
              <Route render={() => <EmployeeListPage />} exact path="/employee-list" />
              <Route render={() => <DashboardPage />} exact path="/dashboard" />
              <Route render={() => <Config />} exact path="/config" />
              <Route render={() => <GoogleSheetsPage />} path="/google-sheets" />
              <Route render={() => <Redirect to="/company-list" />} path="/" />
            </Switch>
          ) : (
            <Switch>
              <Route render={() => <LoginPage />} exact path="/login" />
              <Route render={() => <VerifyPage />} exact path="/verify" />
              <Route render={() => <SetPassword />} exact path="/set-password" />
              <Route render={() => <ForgotPassword />} exact path="/forgot-password" />
              <Route render={() => <Redirect to="/login" />} path="/" />
            </Switch>
          )}

          {/* Error Page */}
          <Route component={NotFound} />
        </Switch>
      </AuthContainer>
    </Router>
  )
}

export default LayoutRouter

import { icons } from 'assets/icons'
import LayoutRouter from 'containers/Router'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setUser } from 'state/redux/actions/user.actions'
import { useCallback } from 'react'
import { HttpRequest } from 'helpers/services'
import { getAccessToken } from 'helpers/cookies'
import 'styles/index.css'
import 'styles/index.scss'
import 'react-toastify/dist/ReactToastify.css'

/* @ts-ignore */
React.icons = icons

const App = () => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [initialized, setInitialized] = useState(false)
  const accessToken = getAccessToken()

  const user = useSelector((state) => get(state, 'user.user'))

  const fetchUserDetail = useCallback(() => {
    setLoading(true)
    HttpRequest('GET', '/admin/users/info')
      .then((res: any) => {
        const data = res.data
        if (data.code === 200 && data.message === 'User information') {
          dispatch(setUser(data?.data))
          setInitialized(true)
        } else {
          dispatch(setUser({}))
        }
      })

      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setLoading(false)
        setInitialized(true)
      })
  }, [])

  useEffect(() => {
    // call user info if it is not initialized AND accessToken is not empty AND user is empty
    if (!initialized && !isEmpty(accessToken) && isEmpty(user)) {
      fetchUserDetail()
    }
  }, [initialized, accessToken, user])

  useEffect(() => {
    if (isEmpty(accessToken)) {
      try {
        // logout user
      } catch (err) {
        setLoading(false)
        console.log('Token Error')
      }
    }
  }, [accessToken])

  return <LayoutRouter isUser={!isEmpty(user) && !loading} loading={loading} />
}

export default App
